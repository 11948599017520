<template>
    <div class="homepage-hero-module">
        <div class="video-container">
            <div :style="fixStyle" class="filter">
                <div style="width: 400px; margin: 100px auto">
                    <div style="font-size: 30px; text-align: center; padding: 30px 0; color: #333">后台管理系统</div>
                    <el-form ref="form" :model="form" size="normal" :rules="rules">
                        <el-form-item prop="username">
                            <el-input prefix-icon="el-icon-user-solid" v-model="form.username" placeholder="请输入账号"></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input prefix-icon="el-icon-lock" v-model="form.password" show-password placeholder="请输入密码"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <div style="display: flex">
                                <el-input prefix-icon="el-icon-key" v-model="form.validCode" style="width: 70%;" placeholder="请输入验证码"></el-input>
                                <ValidCode @input="createValidCode" />
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <el-button style="width: 100%" type="primary" @click="login">登 录</el-button>
                        </el-form-item>
<!--                        <el-form-item><el-button type="text" @click="$router.push('/register')">前往注册 >> </el-button></el-form-item>-->
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import request from "../utils/request";
    import ValidCode from "../components/ValidCode";
    export default {
        name: "Login",
        components: {
            ValidCode,
        },
        data(){
            return{
                form:{role: 1},
                rules: {
                    username: [{required: true, message: '请输入用户名', trigger: 'blur',}],
                    password: [{required: true, message: '请输入密码', trigger: 'blur',}],
                },
                validCode: ''
            }
        },
        created(){
            sessionStorage.removeItem("user")
        },
        methods:{
            // 接收验证码组件提交的 4位验证码
            createValidCode(data) {
                this.validCode = data
            },
            login(){
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        if (!this.form.validCode) {
                            this.$message.error("请填写验证码")
                            return
                        }
                        if(this.form.validCode.toLowerCase() !== this.validCode.toLowerCase()) {
                            this.$message.error("验证码错误")
                            return
                        }
                        request.post("/user/login",this.form).then(res => {
                            console.log(res)
                            if(res.code === '0'){
                                this.$message({
                                    type: "success",
                                    message: "登录成功"
                                })
                                sessionStorage.setItem("user",JSON.stringify(res.data)) //缓存用户数据
                                this.$router.push("/book") //登录成功之后进行页面的跳转
                            }else{
                                this.$message({
                                    type: "error",
                                    message: res.msg
                                })
                            }
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .homepage-hero-module,
    .video-container {
        position: relative;
        height: 100vh;
        overflow: hidden;
    }

    .video-container .poster img{
        z-index: 0;
        position: absolute;
    }

    .video-container .filter {
        z-index: 1;
        position: absolute;
        width: 100%;
    }
    .fillWidth {
        width: 100%;
    }
</style>
